import { onPageLoad } from "helpers";

onPageLoad(initDependsOn);

function initDependsOn() {
  document.querySelectorAll("[data-depends-on]").forEach((el) => {
    const dependantElement = el as HTMLInputElement;
    const dependsOnId = dependantElement.dataset!.dependsOn!;
    const dependsOnElement = document.getElementById(
      dependsOnId,
    )! as HTMLInputElement;

    if (dependantElement.disabled) {
      return;
    }

    dependsOnElement.addEventListener("change", function () {
      dependantElement.disabled = !dependsOnElement.checked;

      if (!dependsOnElement.checked) {
        dependantElement.checked = false;
      }
    });
  });
}

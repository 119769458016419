import "~/like";
import "~/suggest_usernames";
import "~/suggestion_button";
import "~/sidebar";
import "~/hide_flash_messages";
import "~/depends_on";
import PullToRefresh from "pulltorefreshjs";

import React from "react";
import { createRoot } from "react-dom/client";

import { ChecklistRoot } from "../checklist_page";
import "../copy_checklist_to_clipboard";
import { performSyncOnPageLoad } from "../changes_sync";

import { store } from "store";
import { Provider } from "react-redux";
import { onPageLoad } from "helpers";

onPageLoad(() => {
  const isChecklistShowPage = !!document.getElementById("checklist-show-page");
  if (!isChecklistShowPage) {
    return;
  }

  performSyncOnPageLoad();

  const reactContainer = document.getElementById("react-root-element");
  if (reactContainer) {
    const reactRootElement = createRoot(reactContainer);
    reactRootElement.render(
      <Provider store={store}>
        <ChecklistRoot />
      </Provider>,
    );
  }
});

// @ts-expect-error Property 'standalone' does not exist on type 'Navigator'.ts(2339)
if (window.navigator.standalone) {
  PullToRefresh.init();
}

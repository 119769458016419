import React, { useState, FormEvent, SyntheticEvent, useEffect } from "react";
import type { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { createChange } from "create_change";
import { closeItemForm as closeItemFormAction } from "redux/slices/itemFormSlice";
import { Item, UpdateItemChangePayload } from "types";
import {
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  MDXEditor,
  MDXEditorMethods,
  UndoRedo,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  toolbarPlugin,
} from "@mdxeditor/editor";

import "@mdxeditor/editor/style.css";

export const ItemForm = () => {
  const itemToEdit = useSelector(
    (state: RootState) => state.itemForm.value.itemToEdit,
  );
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const ref = React.useRef<MDXEditorMethods>(null);

  useEffect(() => {
    if (itemToEdit) {
      setName(itemToEdit.name);
      setCategory(itemToEdit.category);
      setDescription(itemToEdit.description);
      ref.current?.setMarkdown(itemToEdit.description);
    }
  }, [itemToEdit?.id]);

  const checklist = useSelector((state: RootState) => state.checklist.value);
  const dispatch = useDispatch();

  const categoriesList = checklist.categories.map((category) => (
    <option value={category.name} key={category.id}></option>
  ));

  function closeItemForm(event: SyntheticEvent) {
    event.preventDefault();
    dispatch(closeItemFormAction());
  }

  const updateItem = (item: Item) => {
    const payload: UpdateItemChangePayload = {
      itemId: item.id,
      name: item.name,
    };

    if (name !== item.name) {
      payload.newName = name;
    }

    if (category.trim() !== item.category) {
      payload.category = { old: item.category, new: category.trim() };
    }

    if (description.trim() !== item.description) {
      payload.description = { old: item.description, new: description.trim() };
    }

    return createChange({
      name: "updateItem",
      payload: payload,
    }).then(() => {
      setCategory("");
      dispatch(closeItemFormAction());
    });
  };

  const createItem = () => {
    return createChange({
      name: "createItem",
      payload: {
        itemId: crypto.randomUUID(),
        itemName: name,
        categoryName: category.trim(),
        description: description.trim(),
      },
    }).then(() => {
      ref.current?.setMarkdown("");
    });
  };

  const deleteChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();
    if (itemToEdit) {
      createChange({
        name: "deleteItem",
        payload: {
          itemId: itemToEdit.id,
          itemName: itemToEdit.name,
          categoryName: itemToEdit.category,
        },
      });
    }
    dispatch(closeItemFormAction());
  };

  const handleSubmit = (event: FormEvent) => {
    setDisabled(true);
    event.preventDefault();

    (itemToEdit ? updateItem(itemToEdit) : createItem())
      .then(() => {
        setName("");
        setDescription("");
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  const buttonText = itemToEdit ? "Edit Item" : "Add Item";

  const items = checklist.categories.flatMap((category) => category.items);
  const isAccordionOpenByDefault = items.some(
    (item) => item.category !== "" || item.description !== "",
  );

  return (
    <>
      <form id="item_form" className="item-form p-1" onSubmit={handleSubmit}>
        <div className="d-flex mb-2">
          <div className="me-auto">
            {itemToEdit && (
              <a onClick={deleteChecklistItem} title="Delete item" href="#">
                <i className="bi-trash text-danger"></i>
              </a>
            )}
          </div>
          <button
            type="button"
            className="btn-close"
            value="Close"
            aria-label="Dismiss"
            onClick={closeItemForm}
          ></button>
        </div>
        <input
          className="form-control mb-3"
          type="text"
          name="name"
          id="item_name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          disabled={disabled}
          autoFocus
          placeholder={"Name"}
          autoComplete="off"
        />
        <div className="accordion mb-3" id="accordion">
          <div className="accordion-item border-0">
            <h2 className="accordion-header">
              <button
                className={`accordion-button p-2 ${isAccordionOpenByDefault ? "" : "collapsed"}`}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapse"
                aria-expanded="true"
                aria-controls="collapse"
              >
                More fields
              </button>
            </h2>
            <div
              id="collapse"
              className={`accordion-collapse collapse ${isAccordionOpenByDefault ? "show" : ""}`}
              data-bs-parent="#accordion"
            >
              <div className="accordion-body pt-2 pb-0 px-0">
                <input
                  type="text"
                  name="category"
                  id="category"
                  className="form-control mb-3"
                  value={category}
                  list="existing-categories"
                  onChange={(e) => setCategory(e.target.value)}
                  disabled={disabled}
                  placeholder={"Category"}
                />
                <datalist id="existing-categories">{categoriesList}</datalist>
                <MDXEditor
                  ref={ref}
                  contentEditableClassName="mb-3 description"
                  markdown=""
                  placeholder={"Description"}
                  onChange={(e) => setDescription(e)}
                  plugins={[
                    toolbarPlugin({
                      toolbarContents: () => (
                        <>
                          {" "}
                          <UndoRedo />
                          <BoldItalicUnderlineToggles
                            options={["Bold", "Italic"]}
                          />
                          <CreateLink />
                          <ListsToggle options={["bullet", "number"]} />
                        </>
                      ),
                    }),
                    linkPlugin(),
                    linkDialogPlugin(),
                    listsPlugin(),
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="commit"
          value={buttonText}
          className="btn btn-primary"
          disabled={disabled}
          style={{ width: "100%", marginBottom: "1rem" }}
        />
      </form>
    </>
  );
};
